<template>
    <div>
        <page-title heading="Ratiotec pager" subheading="Ratiotec pager" :icon=icon></page-title>
        <div class="app-main__inner">
            <ratiotec-pager></ratiotec-pager>
        </div>
    </div>
</template>

<script>
    import PageTitle from "../../../Layout/Components/PageTitle.vue";
    import RatiotecPager from "../../../components/settings/moduleSettings/RatiotecPager.vue";

    export default {
        components: {
            PageTitle,
            RatiotecPager
        },

        data: () => ({
            icon: 'pe-7s-plane icon-gradient bg-tempting-azure',
        })
    }
</script>