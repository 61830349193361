<template>
  <v-container fluid>
    <v-card>
      <v-layout>
        <v-flex md12 mt-5 mr-2 ml-5>
          <v-switch
            v-model="enable_ratiotec_pager"
            :label="$t('settings.lang_enableRatiotecPager')"
          />
        </v-flex>
      </v-layout>

      <v-layout>
        <v-flex md12 mr-5 ml-5>
          <v-text-field
            v-model="ip_ratiotec_pager"
            @focus="showTouchKeyboard"
            :data-layout="KEYBOARD.KEYSETS.NORMAL"
            :label="$t('generic.lang_ipAddress')"
            autocomplete="off"
            outlined
            required
            :rules="[rules.required, rules.ipAddress]"
          ></v-text-field>
          <v-text-field
            v-model="timeToCall"
            @focus="showTouchKeyboard"
            :data-layout="KEYBOARD.KEYSETS.NUMERIC"
            :label="$t('generic.lang_timeToCallRatiotecPager')"
            autocomplete="off"
            type="number"
            outlined
            required
            :rules="[rules.isNumber,rules.numberRange(timeToCall,0, 100)]"
          ></v-text-field>
        </v-flex>
      </v-layout>

      <v-layout class="text-right">
        <v-flex md12 mb-5 mr-5 ml-5>
          <v-btn text color="error" @click="goBack">
            {{ $t("generic.lang_prev") }}
          </v-btn>
          <v-btn color="success" @click="saveData">
            {{ $t("generic.lang_save") }}
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard
        class="internalWidth"
        id="onScreenKeyboard"
        :options="touchKeyboard.options"
        :defaultKeySet="touchKeyboard.keySet"
        v-if="touchKeyboard.visible"
        :layout="touchKeyboard.layout"
        :cancel="hideTouchKeyboard"
        :accept="hideTouchKeyboard"
        :input="touchKeyboard.input"
      />
    </div>
  </v-container>
</template>

<script>
import { ENDPOINTS } from "../../../config";
import { Events } from "../../../plugins/events";
import mixin from "../../../mixins/KeyboardMixIns";
import validation from "../../../mixins/validation/validation";

export default {
  components: {},
  mixins: [mixin, validation],
  data() {
    return {
      ENDPOINTS,
      enable_ratiotec_pager: false,
      ip_ratiotec_pager: null,
      timeToCall: 0,
      // ----------------------------------
    };
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    getData() {
      this.axios
        .post(ENDPOINTS.SETTINGS.MODULESETTINGS.RATIOTEC_PAGER.GET, {})
        .then((res) => {
          if (res.status === 200) {
            this.enable_ratiotec_pager = res.data.enable_ratiotec_pager;
            this.ip_ratiotec_pager = res.data.ip_ratiotec_pager;
            this.timeToCall = res.data.time_to_call_ratiotec_pager;
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_errorOccurred"),
            color: "error",
          });
        });
    },
    saveData: function () {
      this.axios
        .post(ENDPOINTS.SETTINGS.MODULESETTINGS.RATIOTEC_PAGER.UPDATE, {
          enable_ratiotec_pager: this.enable_ratiotec_pager,
          ip_ratiotec_pager: this.ip_ratiotec_pager,
          time_to_call_ratiotec_pager: this.timeToCall,
        })
        .then((res) => {
          if (res.data.status === "SUCCESS") {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_savedSuccessfully"),
              color: "success",
            });
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          }
        })
        .catch(() => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_errorOccurred"),
            color: "error",
          });
        });
    },
  },

  mounted() {
    //GET DATA
    this.getData();
  },
};
</script>
